.filter{
    position: relative;
}
.filter__button{
    background: $gray-500;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    cursor: pointer;
}
.filter__button img{
    margin-left: 10px;
    height: 14px;
    width: 14px;
}

.filter__content{
    position: absolute;
    z-index: 3;
    left: 0;
    top: calc(100% + 15px);
    width: 460px;
    background: $white;
    border: 1px solid $gray-300;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    opacity: 0;
    pointer-events: none;
}
.filter__content::before {
    content: "";
    height: 12px;
    width: 12px;
    display: inline-flex;
    background: $white;
    border-top: 1px solid $gray-300;
    border-right: 1px solid $gray-300;
    position: relative;
    transform: translateY(-12px) rotate(-45deg);
    margin-left: 100px;
}
body.--filter-active{
    .filter__button{
        background: $gray-900;
    }
    .filter__content{
        opacity: 1;
        pointer-events: auto;
    }
}
.filter__form{
    padding: 0px 20px 20px 20px;
}
.filter__title{
    margin-top: 40px;
    font-size: 18px;
    font-weight: 500;
}

.filter .form-group{
    margin-bottom: -1px;
}
.filter .btn.--fullWidth{
    margin-top: 20px;
}

@media screen and (max-width: 575px){
    .filter__content {
        position: fixed;
        top: 0;
        right: 0;
        width: 300px;
        z-index: 15;
        overflow-y: scroll;
        left: auto;
        height: 100%;
        border: 0;
        transform: translateX(100%);
        transition: 0.2s ease transform;
        opacity: 1;
        content-visibility: hidden;
    }
    body.--filter-active .filter__content {
        transform: translateX(0%);
        content-visibility: visible;
    }
    .filter{
        margin-bottom: 20px;
    }
    .filter__content::before {
        content: none;
    }
    .filter__form{
        padding: 20px;
    }
    .filter .form-group{
        margin-bottom: 10px;
    }
    .filter__button{
        height: 48px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 5;
    }
    body.--filter-active{
        overflow-y: hidden;
    }
}