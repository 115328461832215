.cart-content{
    padding: 80px 0;
    background: #C2C5D1;
}
.cart-container{
    grid-gap: 6px;
    display: grid;
    position: relative;
    grid-template-columns: minmax(440px, auto) minmax(auto, 440px) ;
}
.cart-container--narrow{
    display: flex;
    justify-content: center;
}
.cart-table{
    background: white;
    padding: 60px 50px;
}
.cart-container--narrow .cart-table{
    width: 100%;
    max-width: 940px;
}
.cart-container--narrow .cart-table__title{
    text-align: center;
    font-size: 28px;
    margin-bottom: 40px;
}
@media screen and (max-width:1200px){
    .cart-content{
        padding: 60px 0;
    }
    .cart-container{
        grid-template-columns: minmax(340px, auto) minmax(auto, 340px) ;
    }
}
@media screen and (max-width:991px){
    .cart-content{
        padding: 30px 0;
    }
    .cart-container{
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width:575px){
    .cart-content{
        padding: 10px 0;
    }  
    .cart-table{
        padding: 40px 20px;
    }
}

.cart-table__title{
    font-weight: 600;
    font-size: 20px;
    color: $gray-900;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin: 0;
}
.cart-table-items{
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    border-top: 1px solid $gray-300;
}

.cart-item{
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid $gray-300;
}
.cart-item.--unavailable{
    background: rgba(214, 24, 24, 0.05);
    border: 1px solid #D61818;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 -10px;
}

.cart-item-thumb-wrap{
    position: relative;
    width: 74px;
    height: 74px;
    padding-left: 0;
    flex-shrink: 0;
    background: $gray-100;
}
.cart-item-thumb{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.cart-item-thumb img{
    max-height: 100%;
    mix-blend-mode: multiply;
}
.cart-item-content{
    padding-left: 20px;
    flex-grow: 1;
}
.cart-item-main{
    display: flex;
    padding-bottom: 0px;
    align-items: center;
    gap: 10px;
}
.cart-item-name{
    padding-bottom: 0px;
    flex-grow: 1;
    color: $gray-900;
    font-weight: 500;
    padding-right: 20px;
}
.cart-item-name-var{
    color: $gray-500;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 0px;
}
.cart-item-stock{
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    color: $gray-500;
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 10px;
}
.cart-item-stock img{
    margin-right: 10px;
}
.cart-item-stock.--available{
    color: $green-500;
}
.cart-item-prices{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center; 
    flex-shrink: 0;  
    width: 110px;
    white-space: nowrap;
}

.cart-item-price-pc{
    color: $gray-500;
    flex-shrink: 0;
    padding-left: 20px;
    text-align: right;
    white-space: nowrap;
    font-size: 14px;
}
.cart-item-price, .cart-item-code-price{
    font-weight: 500;
    color: $red-500;
    padding-left: 20px;
    flex-shrink: 0;
}
.cart-item-bottom{
    display: flex;
}
.cart-item-stepper{

}
.cart-item-stepper input[type="radio"]{
    display: none;
}
.cart-item-remove{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}
.cart-item-remove-icon{
    background: $gray-100;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart-item-remove-icon img{

}
.cart-item-remove-text{
    color: $gray-500;
    font-size: 14px;
}
.cart-table__footer{
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.cart-table__footer-link{
    display: flex;
    align-items: center;
    margin-right: 40px;
}
.cart-table__footer-icon{
    background: $gray-100;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.cart-table__footer-icon img{
    width: 10px;
    transform: rotate(90deg);
}
.cart-table__footer-text{
    color: $gray-500;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.cart-table__export{
    display: flex;
    background: $gray-100;
    padding: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    flex-wrap: wrap;
    gap: 20px 50px;
}
.cart-table__export-group{
    display: flex;
    gap: 10px;
}
.cart-table__export-group .item{
    background: white;
    padding: 2px 8px;
    cursor: pointer;
    color: $gray-900;
}
.cart-table__export-group .item:hover{
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,.05);
}
@media screen and (min-width: 992px){
    .cart-item-stepper{
        flex-shrink: 0;
      }
      .cart-item-name{

      }
}
@media screen and (max-width: 991px){
    .cart-item-main{
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 575px){
    .cart-item {
        padding: 20px 0;
    }
    .cart-item-price-pc {
        display: none;
    }
    .cart-item-remove-text{
        display: none;
    }
    .cart-item-thumb-wrap{
        width: 60px;
        height: 60px;
    }
}



.cart-table-shipping{
    display: flex;
    flex-direction: column;
    padding: 10px 0 40px 0;
    gap: 10px;
}
.cart-table-shipping:last-child{
    padding-bottom: 0px;
}

.cart-table-shipping-item{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 25px 20px; 
    border: 1px solid $gray-300;
    margin-top: -1px;
    cursor: pointer;
    position: relative;
}
.cart-table-shipping-item input {
    appearance: none;
    height: 18px;
    width: 18px;
    border: 1px solid $gray-500;
    border-radius: 50%;
    padding: 0;
    flex-shrink: 0;
}
.cart-table-shipping-item input:checked {
    background-color: $green-500;
    background-image: url("../img/icons/check-white.svg");
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid $green-500;
}
.cart-table-shipping-item input:checked ~ .cart-table-shipping-text::after{
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border: 1px solid $green-500;
    z-index: 2;
}

.cart-table-shipping-item input:disabled ~ .cart-table-shipping-text::after{
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    background: rgba(0,0,0,0.04);
    z-index: 2;
    cursor: not-allowed;
}
.cart-table-shipping-item input:disabled + .cart-table-shipping-img {
    filter: grayscale(1);
}
.cart-table-shipping-img{
    padding-left: 20px;
    height: 24px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        max-height: 100%;
        width: auto;
    }
}
.cart-table-shipping-text{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    align-items: flex-start;
    line-height: 1;
}
.cart-table-shipping-text small{
    color: $gray-500;
    font-size: 14px;
    padding-top: 5px;
}
.cart-table-shipping-text span{
    color: $white;
    font-size: 14px;
    margin-top: 10px;
    padding: 5px 10px;
    background: $green-500;
}
.cart-table-shipping-price{
    padding-left: 20px;
    white-space: nowrap;
}
.cart-table-shipping-price.--free{
    color: $green-500;
}
.cart-table-personal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    margin-bottom: 40px;
}
.cart-table-personal .form-group{
    max-width: 540px;
    width: 100%;
}

@media screen and (max-width: 575px){
    .cart-table-shipping-img{
        display: none;
    }
    .cart-table-shipping-text {
        padding-left: 20px;
    }
}




.cart-aside{
    background: white;
    padding: 60px 40px;
    height: 100%;
}
.cart-aside__title{
    font-weight: 600;
    font-size: 20px;
    color: $gray-900;
    text-transform: uppercase;
    padding-bottom: 0px;
    margin-bottom: 10px;
}
.cart-aside__table{
    padding: 10px 0;
    margin-bottom: 30px;
    border-top: 1px solid $gray-300;
}
.cart-aside__row{
    padding: 5px 0;
    display: flex;
}
.cart-aside__table-th{
    flex-grow: 1;
}
.cart-aside__table-td{
    text-align: right;
}

.cart-aside__row.--main{
    padding: 20px 0;
    margin-top: 20px;
    border-top: 6px solid $gray-100;
    border-bottom: 6px solid $gray-100;
    display: flex;
    flex-direction: column;

    .cart-aside__table-th{
        font-size: 18px;
        font-weight: 500;
    }
    .cart-aside__table-td{
        font-size: 18px;
        color: $red-500;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        text-align: right;
    }
    .cart-aside__table-td small{
        font-size: 13px;
        color: $gray-500;
        font-weight: 400;
    }
}
.cart-aside__table-row{
    display: flex;
    justify-content: space-between;
}
.cart-aside__table-row:last-child{
    font-size: 13px;
    color: $gray-500;
    padding-top: 5px;
}
.cart-aside__table-row:last-child .cart-aside__table-th,
.cart-aside__table-row:last-child .cart-aside__table-td{
    color: $gray-500;
    font-size: 13px;
    font-weight: 400;
}
.cart-aside__free-shipping{
    background: rgba($green-500,0.1);
    margin-bottom: 20px;
    padding: 10px 20px;
    font-weight: 500;
}


.cart-aside__contact{
    display: flex;
    flex-direction: column;
    padding: 30px;
    border: 1px solid $gray-300;
    margin-bottom: 40px;
}
.cart-aside__contact h4{
    font-size: 20px;
}
.cart-aside__contact p{
    color: $gray-500;
    font-size: 14px;
}   
.cart-aside__contact a{
    display: flex;
    gap: 8px;
    padding: 6px 0;
    color: $gray-900;
}
.cart-aside__contact a img{
    width: 18px;
}
.cart-aside__code{
    margin-bottom: 40px;
}
.cart-aside__footer-link{
    display: flex;
    justify-content: center;
}
.cart-aside__footer .btn{
    margin-bottom: 20px;
}
.cart-aside__code .btn{
    position: absolute;
    right: 0;
    bottom: 0;
    height: 54px;
    padding: 0 20px;
}

@media screen and (max-width: 991px){
    .cart-aside{
        padding: 30px 20px;
    }
    
}
@media screen and (max-width: 575px){
    .cart-table-personal{
        border: 0;
        padding: 0;
        margin-top: 0;
    }
}




.cart-summary{

}

.cart-summary__item{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid $gray-300;
}
.cart-summary__item.--unavailable{
    border: 1px solid $red-500;
    padding: 20px 10px;
    margin: 0 -10px;
    width: auto;
}
.cart-summary__item:first-child{
    border-top: 1px solid $gray-300;
}
.cart-summary__item-content{
    display: flex;
    width: 100%;
}
.cart-summary__img{
    height: 60px;
    width: 60px;
    object-fit: contain;
    margin-right: 20px;
    flex-shrink: 0;
}
.cart-summary__item-title{
    padding-right: 20px;
    display: flex;
    flex-grow: 1;
    max-width: calc(100% - 80px);
    flex-direction: column;
}
.cart-summary__item-title span{
    color: $gray-500;
    font-size: 13px;
}
.cart-summary__item-qty{
    white-space: nowrap;
    width: 50px;
    flex-shrink: 0;
}
.cart-summary__item-price{
    font-weight: 500;
    color: $red-500;
    width: 100px;
    text-align: right;
    padding-left: 0px;
    white-space: nowrap;
}
@media screen and (max-width: 575px){
    .cart-summary__item-content{
        flex-direction: column;
    }
    .cart-summary{
        padding: 0 20px;
    }
    .cart-summary__item-title{
        max-width: 100%;
    }
}


.cart-summary__weight{
    color: $gray-500;
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    border-bottom: 1px solid $gray-300  ;
}

.cart-summary__price{
    width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;
    border-collapse: separate;
}
.cart-summary__price td{
    padding: 5px 0;
}
.cart-summary__price td:last-child{
    text-align: right;
    white-space: nowrap;
    font-weight: 500;
}

.cart-summary__price-sum{
    margin-bottom: 60px;
    border-top: 6px solid $gray-100;
    width: 100%;
    padding-top: 20px;
    border-collapse: separate;
    font-size: 18px;
    font-weight: 500;
}
.cart-summary__price-sum td:last-child{
    text-align: right;
    color: $red-500;
}
.cart-summary__price-sum tr:last-child {
    font-size: 14px;
    color: $gray-500;
}
.cart-summary__price-sum tr:last-child td:last-child{
    color: $gray-500;
}
.cart-summary__table{
    width: 100%;
    color: $gray-500;
    margin-bottom: 10px;
    background: $gray-100;
    padding: 25px 40px 20px 40px;
}
.cart-summary__table th{
    width: 100%;
    font-weight: 500;
    border-bottom: 2px solid $white;
    color: $gray-900;
    padding-bottom: 18px;
    text-transform: uppercase;
    font-size: 16px;
}
.cart-summary__table tr:first-child td{
    padding-top: 20px;
}
.cart-summary__table td{
    white-space: nowrap;
    padding: 5px 0;
}
.cart-summary__table td:last-child{
    text-align: right;
    color: $gray-900;
}

.cart-summary button{
    margin-bottom: 40px;
}

.cart-summary .cart-table__footer{
    justify-content: center;
}
.cart-summary__footer{
    padding-top: 40px;
}

.cart-empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 0 80px 0;
}
.cart-empty__img{

}
.cart-empty__title{
    padding: 40px 0 5px 0;
    text-align: center;
}
.cart-empty__text{
    max-width: 380px;
    color: $gray-500;
    text-align: center;
    padding-bottom: 20px;
}
.cart-empty__alert{
    display: flex;
    padding: 20px;
    background: $gray-100;
    margin-top: 40px;
    max-width: 520px;
}
.cart-empty__alert img{

}
.cart-empty__alert-text{
    padding-left: 20px;
}


@media screen and (max-width: 767px){
    .cart-table__footer {
        display: none;
    }
    .cart-table__footer.--step1{
        display: flex;
    }
    .cart-empty{
        padding: 60px 0;
    }
}