.page-heading{
    padding: 40px 0 30px 0;

}
.page-heading .container{
    position: relative;    
}
.page-heading .btn{
    position: absolute;
    top: 0px;
    right: 20px;
    bottom: auto;
    height: 46px;
    padding: 0 20px;
    font-size: 14px;
}
.page.--narrow .page-heading{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.page.--narrow .page-heading .btn{
    position: relative;
    right: 0;
    margin-top: 60px;
}
.page-heading.--inline .container{
    display: flex;
    align-items: center;
}
.page-heading h1{
    padding: 0;
    margin: 0;
    font-size: 32px;
}
.page-heading p{
    color: $gray-500;
    font-size: 15px;
    margin: 0;
}
.page-heading p span{
    display: block;
    color: $gray-900;
}
.page-heading .read-more{
    color: $green-500;
    margin: 0;
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
    &.--less{
        display: flex;
        margin: 0;
        margin-top: 10px;
    }
}
#pageDescriptionFull{
    display: none;
    padding-top: 10px;
}
#pageDescription{
    padding-top: 10px;
}
#pageDescription.--active{
    display: none;
}
#pageDescription.--active + #pageDescriptionFull{
    display: block;
}

.page-heading-inner{
    background-color: $gray-100;
    padding: 30px 20px;
    display: flex;
    gap: 30px;
    img{
        height: 80px;
        width: 80px;
    }
}
.page-heading-inner h1{

}
.page-heading-inner p{
    font-size: 14px;
}

@media screen and (max-width: 575px){
    .page-heading{
        padding: 40px 0;
    }
    .page-heading h1 {
        padding-bottom: 0px;
        font-size: 26px;
    }
    .page-heading p{
        font-size: 14px;
    }
    #pageDescription{
        display: none;
    }
    .page-heading-inner{
        img{
            display: none;
        }
    }
}


