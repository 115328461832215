.carousel{
    padding-top: 20px;
    position: relative;
    /*content-visibility: auto;*/

}
.carousel-wrapper{
    position: relative;
}
.carousel-inner{
   
}
.carousel-inner > .item{
    display: none;
}
.carousel-inner > .item:first-child{
    display: block;
}
.carousel .item{
    position: relative;
    will-change: auto;
}
.carousel .item img{

}
.carousel .item a{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.carousel .prev, .carousel .next{
    background: rgba(0,0,0,0.6);
    height: 52px;
    width: 52px;
    border: 0;
    position: absolute;
    top: 10px;
    right: 10px;
}
.carousel .prev{
    right: 62px;
}
.carousel .prev img{
    transform: rotate(180deg);
}

@media screen and (max-width:991px){
    .carousel{
        padding: 20px 0;
    }
}
@media screen and (max-width:575px){
    .carousel{
        padding: 10px 0;
    }
    .carousel .prev, .carousel .next{
        display: none;
    }
}