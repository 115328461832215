.order-history{
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    font-size: 14px;
}
.order-history table{
    margin: 0 !important;
}
.order-history::-webkit-scrollbar { 
    display: none;
}
.order-history__item{

}
.order-history table{
    width: 100%;
}
.order-history th{
    border: 0 !important;
    padding: 10px 0px;
}
.order-history__item > td{
    max-width: 30%;
    padding: 25px 20px 25px 20px !important;
    border: 0 !important;
}
.order-history td{
    border: 0 !important;
}
.order-history td a{
    text-transform: uppercase;
    color: $gray-900;
    white-space: normal;
}
.order-history__item > td > a{
    color: $gray-900;
    font-weight: 600;
}
.order-history td img{
    height: 46px;
    width: 46px;
    float: left;
    margin-right: 10px;
}
.order-history td:first-child{
    font-weight: 500;
}
.order-history__item , .order-history__item-detail{
    border: 1px solid $gray-300;
}
.order-history__item-detail td:first-child{
    padding-left: 10px;
    max-width: 300px;
}
.order-history span{
    display: flex;
    padding: 0;
}
.order-history__item-detail{
    display: none;
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    background: $white;
}
.order-history__item:target + .order-history__item-detail{
    display: table-row;
}
.order-history__item-detail > td{
    padding: 10px 0 !important;
}
.order-history__item-detail td table{
    margin: 0;
}
.order-history__item-detail td table td{
    border: 0;
    padding: 10px 5px;
    font-weight: 400;
}

.order-history__item-detail td table td span.--status{
    color: $green-500;
    font-weight: 500;
    text-transform: uppercase;
}

@media screen and (max-width: 575px){
    .order-history{
        padding: 0 20px;
    }
}


.order-history__item-detail tr{

    align-items:center;
}

.order-history__files{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}
.order-history__item-detail td .order-history__files a{
    color: $gray-500;
    text-transform: none;
}