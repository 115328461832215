@charset 'utf-8';

/* ========================================================================== */
/* BOOTSTRAP */
/* ========================================================================== */


@import "node_modules/bootstrap/scss/bootstrap-reboot";
@import "node_modules/bootstrap/scss/bootstrap-grid";


/* ========================================================================== */
/* VARIABLES */
/* ========================================================================== */

@import "variables/_colors.scss";
@import "variables/_fonts.scss";

/* ========================================================================== */
/* COMPONENTS */
/* ========================================================================== */

@import "components/_alert.scss";
@import "components/_article.scss";
@import "components/_autocomplete.scss";
@import "components/_blog.scss";
@import "components/_breadcrumbs.scss";
@import "components/_btn.scss";
@import "components/_carousel.scss";
@import "components/_cart-content.scss";
@import "components/_cart-heading.scss";
@import "components/_categories.scss";
@import "components/_contact.scss";
@import "components/_cookiebar.scss";
@import "components/_filter.scss";
@import "components/_footer.scss";
@import "components/_form.scss";
@import "components/_header.scss";
@import "components/_lightbox.scss";
@import "components/_modal.scss";
@import "components/_nav.scss";
@import "components/_news-section.scss";
@import "components/_news-single.scss";
@import "components/_order-history.scss";
@import "components/_page-content.scss";
@import "components/_page-heading.scss";
@import "components/_page.scss";
@import "components/_pagination.scss";
@import "components/_points.scss";
@import "components/_product-card.scss";
@import "components/_product-detail.scss";
@import "components/_products-footer.scss";
@import "components/_products-grid.scss";
@import "components/_products-heading.scss";
@import "components/_products-sort.scss";
@import "components/_range-slider.scss";
@import "components/_searchAutocomplete.scss";
@import "components/_social.scss";
@import "components/_stepper.scss";
@import "components/_subcategories.scss";
@import "components/_tag.scss";

:root{
    scroll-behavior: auto;
}
html {
    background: $gray-200;
}

body {
    background: $white;
    color: $gray-900;
    font-family: $font-family-primary;
    overflow-x: hidden;
    font-size: 15px;
    max-width: 1920px;
    margin: 0 auto;
}

body.cart #navToggle {
    display: none;
}

#wrapper {
    display: grid;
    position: relative;
    grid-template-columns: minmax(auto, 320px) minmax(320px, auto);
}

@media screen and (max-width: 1300px) {
    #wrapper {
        grid-template-columns: minmax(auto, 280px) minmax(280px, auto);
    }
}

@media screen and (max-width: 991px) {
    #wrapper {
        display: flex;
        flex-direction: column;
    }
}

a {
    color: $green-500;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        color: $gray-900;
    }
}

img,
svg {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

strong {
    font-weight: 500;
}

h1 {
    font-size: 36px;
}

.container {
    max-width: 1460px;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    margin: 0 auto;
}

.row {
    margin-right: -10px;
    margin-left: -10px;
}

.row>* {
    padding-right: 10px;
    padding-left: 10px;
}

@media screen and (max-width: 575px) {
    .container {
        padding-right: 10px;
        padding-left: 10px;
    }

    .row {
        margin-right: -5px;
        margin-left: -5px;
    }

    .row>* {
        padding-right: 5px;
        padding-left: 5px;
    }
}

main {
    width: 100%;
}

section {
    padding: 40px 0;
}

section h1 {
    font-size: 26px;
    font-weight: 700;
    padding-bottom: 30px;
    margin: 0;
    color: $gray-900;
    text-transform: uppercase;
}

@media screen and (max-width: 575px) {
    section {
        padding: 20px 0;
    }

    section h1 {
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 20px;
    }
}

section.products {
    padding-bottom: 20px;
}




#darkBackdrop {
    display: flex;
    opacity: 0;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

@media screen and (max-width: 991px) {
    #darkBackdrop {}

    body.--nav-active #darkBackdrop {
        opacity: 1;
        pointer-events: auto;
    }

    body.--search-active #darkBackdrop {
        opacity: 1;
        pointer-events: auto;
    }

    body.--filter-active #darkBackdrop {
        opacity: 1;
        pointer-events: auto;
    }
}


.logout-wrap {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.delete-account {
    margin-top: 60px;
}

.delete-account h2 {
    margin-bottom: 10px;
}


.cart-aside__free-shipping {
    display: none;
}


#modal-ppl .modal__container {
    max-width: 100vw;
    max-height: 100vh;
}

#ppl-parcelshop-map .smap {
    touch-action: none;
    height: 80vh !important;
}


.product-detail__wholesale {
    margin-bottom: 40px;
}

.product-detail__wholesale-title {
    font-size: 14px;
    line-height: 1;
    color: $gray-500;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.product-detail__wholesale-table {}

.product-detail__wholesale-table td {
    padding: 8px 15px;
    background: $gray-100;
    border: 1px solid white;
}


.variants.--wholesale .variants__list-wrap,
.variants.--wholesale .variants__list {
    height: 100%;
}

.variants.--wholesale .variants__list::after {
    content: none;
}

.variants.--wholesale .variants__item.--active {
    order: initial;
    background: rgba(57, 152, 65, 0.1);
    box-shadow: inset 0px 0px 0px 1px rgb(57 152 65);
    z-index: 99;
}

.variants.--wholesale .variants__item {
    order: initial;
}

.variants.--wholesale .variants__list.--active {
    box-shadow: none;
}


#mainNav {
    width: 100%;
    flex-shrink: 0;
}

.splashscreen {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.splashscreen-item {
    border: 1px solid $gray-300;
    display: flex;
    padding: 12px 15px;
    gap: 15px;
    transition: 0.3s ease box-shadow;
}

.splashscreen-item:hover {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, .05);
}

.splashscreen-item-img {
    background: $gray-100;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 46px;
    width: 46px;
}

.splashscreen-item-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}

.splashscreen-item-text span {
    color: $gray-900;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.3;
}

.splashscreen-item-text small {
    font-size: 13px;
    color: $gray-500;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding-top: 2px;
}

.splashscreen-item-text small b {
    font-weight: 400;

}

.splashscreen-item .btn {
    height: 48px;
    font-size: 14px;
    padding: 0 20px;

}


.user-info-section {
    padding-bottom: 60px;
}

.user-info-section:first-child {
    margin-top: 40px;
}

.user-info-section:last-child {
    padding-bottom: 0px;
}

.user-info-section h3 {
    font-size: 18px;
    border-bottom: 1px solid $gray-300;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.user-info-usergrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
}

.usergrid-item {
    border: 1px solid #e5e5e5;
    display: flex;
    padding: 12px 15px;
    gap: 15px;
    transition: .3s ease box-shadow;
    align-items: center;
}

.usergrid-item-img {
    background: #f5f5f7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 46px;
    width: 46px;
}

.usergrid-item-img img {}

.usergrid-item-text span {
    color: #222427;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.3;
}

.usergrid-item-text small {
    font-size: 14px;
    color: #6e6e6e;
    display: flex;
    gap: 20px;
}

.user-info-table {}

.user-info-table table {
    width: 100%;
    margin-bottom: 0;
}

.user-info-table table td {
    border: 0;
    padding: 5px 0;
}

.user-info-table table td:last-child {
    text-align: right;
}

.user-info-section .form-group.--inline {
    margin: 0;
}

.user-info-section .form-group.--inline select {
    width: 100%;
    height: 48px;
}

.user-info-section .form-group.--inline+.user-info-table {
    margin-top: 20px;
}

.user-info-section .btn.btn-secondary {
    padding: 0 15px;
    font-size: 15px;
    flex-shrink: 0;
}

.tab {
    display: flex;
    flex-wrap: wrap;
}

.tablinks {
    border: 0;
    background: transparent;
    padding: 12px 20px;
    text-transform: uppercase;
}

.tablinks.active {
    background: rgba($green-500, 0.1);
    color: $green-500;
    border-bottom: 2px solid $green-500;
}

.tabcontent {
    border: 1px solid $gray-300;
    margin-top: -1px;
}

.user-files-table {
    padding: 30px 20px;
    overflow: auto;
}

.user-files-table table {
    margin: 0;
    width: 100%;
    font-size: 14px;
}

.user-files-table table th {
    color: $gray-500;
    border: 0;
    padding: 10px;
    font-weight: 400;
}

.user-files-table table td {
    padding: 8px 10px;
    border: 0;
    color: $gray-900;
}

.user-files-table table tr:nth-child(even) td {
    background: $gray-100;
}

.user-files-table table td.--bold {
    font-weight: 600;
}

.user-files-table table td.--red {
    color: $red-500;
    font-weight: 600;
}

.user-files-table-footer {
    background: $gray-100;
    padding: 30px;
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    font-size: 18px;

    span {
        display: flex;
        align-items: baseline;
        gap: 10px;
    }
}

.user-files-table-footer strong {
    font-weight: 600;
    margin: 0;
}

.user-files-table-footer span:last-child strong {
    color: $red-500;
}



.product-history {}

.product-history__item {
    margin-bottom: 40px;
}

.product-history__item:last-child {
    margin: 0;
}

.product-history__heading {
    display: flex;
    background: $gray-100;
    padding: 12px 15px;
    flex-direction: column;
}

.product-history__heading h5 {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    line-height: 1.5;
}

.product-history__heading h5 span {
    font-weight: 400;
    color: $gray-500;
}

.product-history__heading p {
    margin: 0;
    font-size: 14px;
}

.product-history__product {
    display: flex;
    gap: 15px;
    padding: 20px 0;
    align-items: center;
    border-bottom: 1px solid $gray-300;
}

.product-history__qty {
    white-space: nowrap;
    flex-shrink: 0;
}

.product-history__product img {
    height: 60px;
    width: 60px;
}

.product-history__name {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    flex-grow: 1;
}

.product-history__name span:last-child {
    font-size: 13px;
    font-weight: 400;
    color: $gray-500;
}

.product-history__btn {
    padding: 0 15px;
    font-size: 14px;
    white-space: nowrap;
    flex-shrink: 0;
}

@media screen and (max-width: 575px) {
    .product-history {
        padding: 0 20px;
    }

    .product-history__product {
        flex-wrap: wrap;
    }

    .product-history__name {
        flex-grow: 1;
        width: calc(100% - 80px);
    }

    .product-history__qty {
        flex-grow: 1;
    }
}


.page-content .notice {
    margin: 0;
}

form .notice {
    border: 1px solid $gray-300;
    border-left: 3px solid $yellow-500;
    padding: 25px 25px 25px 30px;
    display: flex;
    gap: 15px;
    align-items: center;
}

.cart-aside__item {
    display: flex;
    gap: 15px;
    padding: 20px 0;
    border-bottom: 1px solid $gray-300;
}

.cart-aside__item-img {
    height: 60px;
    width: 60px;
    background: $gray-100;
    flex-shrink: 0;
}

.cart-aside__item-img img {
    mix-blend-mode: multiply;
}

.cart-aside__item-content {
    display: flex;
    flex-direction: column;
}

.cart-aside__item-name {
    line-height: 1.3;
    padding-bottom: 5px;
}

.cart-aside__item-prices {
    display: flex;
    justify-content: space-between;
}

.cart-aside__item-prices span {
    font-size: 14px;
}

.cart-aside__item-prices span:last-child {
    font-weight: 500;
}

.cart-aside__weight {
    padding: 12px 0;
    border-bottom: 1px solid $gray-300;
    color: $gray-500;
    font-size: 13px;
}

.cart-aside__delivery {
    padding-top: 20px;
}

.cart-table-address {
    width: 100%;
}

.cart-table-address table {
    width: 100%;
}

.cart-table-address td {
    padding: 5px 0;
}

.cart-table-address td:first-child {
    color: $gray-500;
}

.cart-table-address td:last-child {
    text-align: right;
}

.cart-table-personal .form-group.--inline {
    max-width: 100%;
}

.cart-table-personal .form-group.--inline select {
    width: 100%;
}

.cart-table-personal .form-group.--inline .btn {
    white-space: nowrap;

}

.cart-table-personal {
    padding: 30px 0;
    border: 0;
    border-top: 1px solid $gray-300;
}

.form-group.--radiogroup {
    margin-bottom: 10px;
}

.form-group.--radiogroup p {
    font-weight: 600;
    text-transform: uppercase;
}

.form-group.--radiogroup span {
    display: flex;
    align-items: center;
    gap: 15px;
}

.form-group.--radiogroup label {
    padding: 0;
    width: 100%;
}

.cart-table__footerSummary {
    width: 100%;
    padding: 20px;
    background: $gray-100;
    margin-bottom: 30px;
}

.cart-table__footerSummary-row {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
}

.cart-table__footerSummary-row:first-child {
    font-size: 18px;
    font-weight: 500;
}

.cart-table__footerSummary-row:first-child span:last-child {
    color: $red-500;
}

.cart-table__footerSplit {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
    padding-bottom: 30px;

}

.cart-table__footerSplit .form-group {
    margin: 0;
    flex-shrink: 0;
}

.cart-table__footerSplit p {
    margin: 0;
    font-size: 14px;
    color: $red-500;
    font-weight: 500;
    text-transform: uppercase;
}

.product-detail__tab {
    margin-top: 60px;
    background: $gray-100;
    padding: 2px;
}

.product-detail__tab .tablinks {
    margin-right: 2px;
    color: $gray-500;
}

.product-detail__tab .tablinks.active {
    border: 0;
    background: $white;
    color: $gray-900;
}

.product-detail__tabcontent {
    border: 0;
}

.product-detail__files {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 40px;
}

.product-detail__files a {
    border: 2px solid $gray-100;
    padding: 15px;
    color: $gray-900;
    font-weight: 500;
    background-image: url("../img/icons/download.svg");
    background-position: center right 15px;
    background-repeat: no-repeat;
}


.cart-table-personalAcc {
    padding: 30px;
    border: 1px solid $gray-300;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.cart-table-personalAcc img {
    height: 42px;
    opacity: 0.4;
    margin-left: 10px;
}

.cart-table-personalAcc p {
    margin: 0;
    padding: 0;
    color: $gray-900;
    flex-grow: 1;
}

.cart-table-personalAcc .btn {
    padding: 0 15px;
    font-weight: 500;
}

@media screen and (max-width: 575px) {
    .cart-table-personalAcc {
        padding: 20px;
    }

    .cart-table-personalAcc .btn {
        white-space: nowrap;
    }

    .cart-table-personalAcc img {
        display: none;
    }

    .cart-table__footerSplit {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 991px) {

    .product-detail__files {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
}

#register-form {
    margin-bottom: 40px;
}


section.bestsellers {
    padding-bottom: 0;
}

.bestsellers h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    padding-bottom: 10px;
}

.bestsellers-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}

.bestsellers-single {
    display: flex;
    border: 1px solid $gray-300;
    padding: 10px;
    gap: 15px;
}

.bestsellers-single-img {
    background-color: $gray-100;
    height: 60px;
    width: 60px;
    flex-shrink: 0;
}

.bestsellers-single img {
    mix-blend-mode: multiply;
}

.bestsellers-single-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $gray-900;
    width: 100%;
}

.bestsellers-single-content p {
    margin: 0;
    padding-bottom: 5px;
}

.bestsellers-single-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.bestsellers-single-bottom span {
    color: $green-500;
}

.bestsellers-single-bottom strong {
    flex-grow: 1;
    text-align: right;
    padding-right: 10px;
}

@media screen and (max-width: 991px) {
    .bestsellers-grid {
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }
}


.product-card__removefav {
    color: $red-500;
    background: rgba($red-500, 0.1);
    width: 100%;
    height: 48px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.header-nav .user__dropdown {
    padding-bottom: 0;
}

.header-nav .user__dropdown a:last-child {
    border-top: 1px solid $gray-300;
    margin-top: 10px;
}

.header-nav .user__dropdown a.--bold {
    font-weight: 500;
    border-bottom: 1px solid $gray-300;
    color: $green-500;
    margin-bottom: 10px;
}

.next-lightbox {
    background-color: white;
    padding: 5px;
}

@media screen and (max-width: 991px) {
    #lightbox {
        display: none !important;
    }
}

#productGalleryModal {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    display: flex;
}

body.--activeProductCarousel {
    overflow: hidden;
}

body.--activeProductCarousel #productGalleryModal {
    opacity: 1;
    pointer-events: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;

    z-index: 99;
    justify-content: center;
}

body.--activeProductCarousel #productGalleryBackdrop {
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

#productGalleryModal .modal-dialog {
    position: relative;
    max-width: 1300px;
}

#productGalleryModal .slider-nav-dark .slider-nav::after {
    background-color: white;
}

#productGalleryClose{
    background: white;  
    padding: 5px 10px;
    display: inline-flex;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    cursor: pointer;
}

.page-content li strong{
    display: inline-block;
    margin: 0;
  }
  .user__company{
    color: #fff;
    font-size: 13px;
        white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 1.2;
  }

#productGallery .slider-item-ratio .slider-container>*>* {
    object-fit: contain;
}

#productGallery .slider-container>* {
    background: #fafafa
}

#productGallery #pgallery.slider-item-ratio-1x1 {
    --swiffy-slider-item-ratio: 4/3
}

@media screen and (min-width: 992px) {
    .nav-item.expandable:hover .nav-submenu {
        opacity: 0;
        pointer-events: none;
    }

    .nav-item.expandable.result_hover .nav-submenu {
        opacity: 1;
        pointer-events: auto;
    }

    .nav-item.expandable .nav-submenu:hover {
        pointer-events: auto;
        opacity: 1;
    }

    .nav-submenu__single ul {
        padding-bottom: 0;
        padding-left: 25px;
    }

    .nav-submenu__single {
        padding: 0px 0;
    }

    .nav-submenu .row {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 40px 20px;
        padding: 40px 0;
    }

    .nav-submenu .row .col-lg-4 {
        width: 100%;
        padding: 0;
    }

    .nav-submenu__title {
        font-size: 15px;
        padding-bottom: 5px;
    }

    .nav-submenu__single li {
        font-size: 13px;
    }

    .nav-submenu__single img {
        width: 60px;
    }
}

@media screen and (max-width: 991px) {
    .header-nav .user__wrap {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .user-files-table-footer {
        flex-direction: column;
        gap: 10px;
    }

    .cart-item {
        position: relative;
    }

    .cart-item-remove {
        position: absolute;
        bottom: 30px;
        left: 0;
    }

    .cart-summary__table td {
        white-space: normal;
    }

    .tab .tablinks {
        width: 100%;
        border: 1px solid #e5e5e5;
    }
}


.footer__icons {
    display: flex;
    gap: 10px;
    padding-top: 15px;
}

.footer__icons img {
    height: 40px;
}

.nav-submenu {
    display: none;
}

.lightbox {
    opacity: 1;
    visibility: visible;
    transition: all 0s ease;
}

.fotogalerie-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    margin: 60px 0 0 0;
}

.fotogalerie-wrap img {
    aspect-ratio: 4/3;
}

@media screen and (max-width: 575px) {
    .fotogalerie-wrap {
        grid-template-columns: 1fr 1fr;
    }
}


.products-grid .product-card.--unavailable .product-card__link::before,
.product-detail__cta.--unavailable::before {
    content: "Upozornění: vkládáte do košíku více kusů než je skladem!";
    text-align: center;
    display: block;
    position: absolute;
    z-index: 9;
    background: #c40808;
    color: white;
    padding: 0 5px;
    left: 5px;
    top: 5px;
    right: 5px;
    font-size: 14px;
}

.product-detail__cta.--unavailable {
    flex-wrap: wrap;
}

.product-detail__cta.--unavailable::before {
    position: relative;
    width: 100%;
    max-width: 470px;
    margin-bottom: 30px;
    padding: 10px;
}

#obal_rozdelit.--show{
    display: flex !important;
  }
  #obal_rozdelit.--hide{
    display: none !important;
  }
  .cart-table__footerSplit{
    flex-wrap: wrap;
    justify-content:center;
    background: rgba(214,24,24,.05);
    padding: 30px 20px;
  }
  .cart-table__footerSplit p{
    width: 100%;
    text-align:center;
  }
  .cart-table__footerSplit .form-group.--checkbox>label{
    background: white;
  }