.products-grid{
    display: grid;
    grid-template-columns: repeat(4,minmax(0,1fr));
    grid-gap: 1px;

}
.products-grid .product-card{
    width: 100%;
    outline: 1px solid $gray-300;
}
.products-count{
    color: $gray-500;
    font-size: 14px;
    padding-bottom: 20px;
}
@media screen and (max-width: 1300px){
    .products-grid{
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
}
@media screen and (max-width: 991px){
    .products-grid{
        grid-template-columns: repeat(3,minmax(0,1fr));
    }
}
@media screen and (max-width: 767px){
    .products-grid{
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
    .products-grid .product-card{
        width: 100%;
    }
    .products-count{
        display: none;
    }
}
@media screen and (max-width: 575px){
    .products-grid{
        grid-template-columns: repeat(1,minmax(0,1fr));
        margin-left: -5px;
        margin-right: -5px;
    }
}