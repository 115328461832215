.tag{
    font-size: 13px;
    font-weight: 400;
    background: rgba($gray-900,0.1);
    color: $gray-900;
    height: 20px;
    padding: 0 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
}
.tag.--sale{
    background: rgba($red-500,0.1);
    color: $red-500;
}
.tag.--new{
    background: rgba($green-500,0.1);
    color: $green-500;
}
.tag.--recommended{
    background: rgba($blue-500,0.1);
    color: $blue-500;
}
.tag.--top{
    background: rgba($yellow-500,0.1);
    color: $yellow-500;
}
.tag.--circleSale{
    background: transparent;
    height: 75px;
    width:60px;
    background-image: url("/img/icons/sale.svg");
    background-size: contain;
    font-size: 16px;
    color: white;
    font-weight: 500;
}
.product-card__thumb .tag.--circleSale{
    background-image: none;
    width: 54px;
    height: 30px;
    background-color: $red-500;
    font-size: 14px;
}
.tag.--circle{
    background: #f7e517;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 12px;
    color: black;
    font-weight: 500;
}
.tag.--free-delivery{
    background: $green-500;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 13px;
    text-align: center;
    line-height: 1;
    color: white;
}



@media screen and (max-width:575px){
    .tag{
        font-size: 12px;
        font-weight: 400;
        height: 22px;
        padding: 0 6px;
        margin-bottom: 2px;
    }
}
